import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import styled from 'styled-components';
import { graphql, useStaticQuery } from "gatsby";
import './work-vision.css';

import IntroductionLayout from "../../container/IntroductionLayout"

const Description = styled.div`
  width: calc(100% - 50px);
  margin: 0 auto 20px;
  font-size: 24px;
  line-height: 40px;
  
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
`

const SelectBar = styled.div`
  display: flex;
  margin-left: 20px;
  margin-bottom: 60px;
  
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`

const LocationRadio = styled.input.attrs({
  type: 'radio'
})`
  display: none;
  
  & + label {
    background-color: var(--second-color);
  }
  
  &:checked + label {
    background-color: transparent;
  }
`;

const LocationLabel = styled.label`
  border: 1px solid var(--second-color);
  font-weight: bold;
  display: block;
  width: 130px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-left: 10px;
  cursor: pointer;
  
  @media screen and (max-width: 768px) {
    margin-right: 10px;
    margin-left: 0;
    
    :last-child {
      margin-right: 0;
    }
  }
`


const Map = styled.div.attrs({
  id: 'naver-map'
})`
  width: 700px;
  height: 500px;
  margin: 0 auto;
  
  @media screen and (max-width: 768px) {
    width: 320px;
    height: 240px;
  }
`;

const LocationDescriptionContainer = styled.div`  
  padding-bottom: 200px;
  padding-top: 50px;
  padding-left: 170px;
  
  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
`

const LocationDescription = styled.div`
  padding-left: 10px;
  border-left: 3px solid var(--second-color);
`

function LocationPage () {
  const [isMapReady, setMapIsReady] = useState(false);
  const [map, setMap] = useState();
  const [locationIndex, setLocationIndex] = useState(0);
  const mapContainer = useRef();
  const {
    allLocationJson: { edges },
    site: { siteMetadata }
  } = useStaticQuery(graphql`
      query {
          allLocationJson {
              edges {
                  node {
                      id
                      name
                      fax
                      location {
                          x
                          y
                      }
                      telephone
                      address
                  }
              }
          }
          site {
              siteMetadata {
                  email
                  phone
              }
          }
      }
  `)

  const handleSelect = index => () => setLocationIndex(index)


  useLayoutEffect(() => {
    let script = document.createElement('script')
    script.src = 'https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=0o6uln75cc'
    script.async = false
    const callback = () => {
      setMapIsReady(true)
    }
    script.addEventListener('load', callback)

    document.body.appendChild(script)
  }, [])

  const { address, telephone, fax } = edges[locationIndex].node

  useEffect(() => {
    if(isMapReady)
    {
      let tempMap = new window.naver.maps.Map(mapContainer.current, {
        center: new window.naver.maps.LatLng(
          edges[0].node.location.x,
          edges[0].node.location.y
        ),
        zoom: 14
      })
      setMap(tempMap)
      edges.forEach(({node}) => {
        new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(
            node.location.x,
            node.location.y
          ),
          map: tempMap,
          minZoom: 7,
          maxZoom: 21,
          zoom: 14
        })
      })
    }
  }, [isMapReady]);

  useEffect(() => {
    if(map) {
      map.setOptions({
        zoom: 14
      })
      map.setCenter(new window.naver.maps.LatLng(
        edges[locationIndex].node.location.x,
        edges[locationIndex].node.location.y
      ))
    }
  }, [locationIndex])

  return (
    <IntroductionLayout title="오시는 길">
      <Description>
        문의전화: {siteMetadata.phone}
        <br/>
        이메일: {siteMetadata.email}
      </Description>
      <SelectBar>
        {edges.map(({node}, index) => (
          <React.Fragment key={node.id}>
            <LocationRadio
              id={node.id}
              checked={locationIndex === index}
              onChange={handleSelect(index)}/>
            <LocationLabel
              htmlFor={node.id}>
              {node.name}
            </LocationLabel>
          </React.Fragment>
        ))}
      </SelectBar>
      <Map ref={mapContainer} />
      <LocationDescriptionContainer>
        <LocationDescription>
          주소: <address>{address}</address>
          <br/>
          TEL: {telephone}
          {fax &&
          <>
            <br/>
            FAX: {fax}
          </>
          }
        </LocationDescription>
      </LocationDescriptionContainer>
    </IntroductionLayout>
  )
}

export default LocationPage